import { valueFromKey } from '@/utils'
import dayjs from 'dayjs'

/**
 * @description immutable structure returned by the api
 */
export class Dto {
  ID;
  CreatedAt;
  DeletedAt;
  DeletedBy;
  UpdatedAt;
  UpdatedBy;
  ShopID;
  ExternalID;
  ExternalSystem;
  Address1;
  Address2;
  Birthday;
  City;
  ContactFirstName;
  ContactLastName;
  CustomerType;
  DoNotCall;
  DoNotCallInternal;
  Email;
  FirstName;
  FullAddress;
  FullName;
  LastName;
  Notes;
  PhoneNumber;
  PhoneID;
  PostalCode;
  State;

  constructor () {
    this.ID = null
    this.CreatedAt = null
    this.DeletedAt = null
    this.DeletedBy = null
    this.UpdatedAt = null
    this.UpdatedBy = null
    this.ShopID = null
    this.ExternalID = null
    this.ExternalSystem = null
    this.Address1 = null
    this.Address2 = null
    this.Birthday = null
    this.City = null
    this.ContactFirstName = null
    this.ContactLastName = null
    this.CustomerType = null
    this.DoNotCall = null
    this.DoNotCallInternal = null
    this.Email = null
    this.FirstName = null
    this.FullAddress = null
    this.FullName = null
    this.LastName = null
    this.Notes = null
    this.PhoneNumber = null
    this.PhoneID = null
    this.PostalCode = null
    this.State = null
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromApi (a) {
    const r = new Dto()
    r.ID = valueFromKey('ID', a)
    r.CreatedAt = valueFromKey('CreatedAt', a)
    r.DeletedAt = valueFromKey('DeletedAt', a)
    r.DeletedBy = valueFromKey('DeletedBy', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    r.UpdatedBy = valueFromKey('UpdatedBy', a)
    r.ShopID = valueFromKey('ShopID', a)
    r.ExternalID = valueFromKey('ExternalID', a)
    r.ExternalSystem = valueFromKey('ExternalSystem', a)
    r.Address1 = valueFromKey('Address1', a)
    r.Address2 = valueFromKey('Address2', a)
    r.Birthday = valueFromKey('Birthday', a)
    r.City = valueFromKey('City', a)
    r.ContactFirstName = valueFromKey('ContactFirstName', a)
    r.ContactLastName = valueFromKey('ContactLastName', a)
    r.CustomerType = valueFromKey('CustomerType', a)
    r.DoNotCall = valueFromKey('DoNotCall', a)
    r.DoNotCallInternal = valueFromKey('DoNotCallInternal', a)
    r.Email = valueFromKey('Email', a)
    r.FirstName = valueFromKey('FirstName', a)
    r.FullAddress = valueFromKey('FullAddress', a)
    r.FullName = valueFromKey('FullName', a)
    r.LastName = valueFromKey('LastName', a)
    r.Notes = valueFromKey('Notes', a)
    r.PostalCode = valueFromKey('PostalCode', a)
    r.PhoneID = valueFromKey('PhoneID', a)
    r.PhoneNumber = valueFromKey('PhoneNumber', a)
    r.State = valueFromKey('State', a)
    return r
  }
}

export class Model {
  ExternalID;
  ExternalSystem;
  Address1;
  Address2;
  Birthday;
  City;
  ContactFirstName;
  ContactLastName;
  CustomerType;
  DoNotCall;
  DoNotCallInternal;
  Email;
  FirstName;
  FullAddress;
  FullName;
  LastName;
  Notes;
  PostalCode;
  State;

  constructor () {
    this.ExternalID = null
    this.ExternalSystem = null
    this.Address1 = null
    this.Address2 = null
    this.Birthday = null
    this.City = null
    this.ContactFirstName = null
    this.ContactLastName = null
    this.CustomerType = null
    this.DoNotCall = null
    this.DoNotCallInternal = null
    this.Email = null
    this.FirstName = null
    this.FullAddress = null
    this.FullName = null
    this.LastName = null
    this.Notes = null

    this.PostalCode = null
    this.State = null
  }

  get BirthdayFormat () {
    let r = null
    if (this.Birthday) r = dayjs(this.Birthday).format('YYYY-MM-DD')
    return r
  }

  static fromDto (a) {
    const r = new Model()
    r.ExternalID = valueFromKey('ExternalID', a)
    r.ExternalSystem = valueFromKey('ExternalSystem', a)
    r.Address1 = valueFromKey('Address1', a)
    r.Address2 = valueFromKey('Address2', a)
    r.Birthday = valueFromKey('Birthday', a)
    r.City = valueFromKey('City', a)
    r.ContactFirstName = valueFromKey('ContactFirstName', a)
    r.ContactLastName = valueFromKey('ContactLastName', a)
    r.CustomerType = valueFromKey('CustomerType', a)
    r.DoNotCall = valueFromKey('DoNotCall', a)
    r.DoNotCallInternal = valueFromKey('DoNotCallInternal', a)
    r.Email = valueFromKey('Email', a)
    r.FirstName = valueFromKey('FirstName', a)
    r.FullAddress = valueFromKey('FullAddress', a)
    r.FullName = valueFromKey('FullName', a)
    r.LastName = valueFromKey('LastName', a)
    r.Notes = valueFromKey('Notes', a)
    r.PostalCode = valueFromKey('PostalCode', a)
    r.State = valueFromKey('State', a)
    return r
  }
}

export const Rules = {
  Email: [
    (v) => !!v || 'E-mail is required',
    (v) => {
      return /.+@.+\..+/.test(v) || 'E-mail must be valid'
    }
  ],
  Name: [
    (v) => !!v || 'Name is required',
    (v) => {
      return (v && v.length >= 3) || 'Name must be at least 3 characters'
    }
  ],
  Phone: [(v) => !!v || 'Phone is required']
}
