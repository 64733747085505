import { Dto } from '@/customers/viewModel'
import { Dto as Phone } from '@/customer-phones/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

export const customers = {
  actions: {
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const b = await api.get(`/Customers/${id}`, tok)
      const r = Dto.fromApi(b)
      context.commit('setDto', r)
    },
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchPhones (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const b = (await api.get(`/Customers/${id}/Phone-Numbers`, tok)) ?? []
      const r = b.map((e) => Phone.fromJson(e))
      context.commit('setPhones', r)
    },
    /**
     * @param args {Object} - Vuex actions only support one parameter
     * @param args.model {Model} - Payload
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, args) {
      const m = valueFromKey('model', args)
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const d = await api.put(`/Customers/${id}`, m, tok)
      context.commit('setDto', d)
    }
  },
  getters: {
    dto (s) {
      return s.dto
    },
    phones (s) {
      return s.phones
    }
  },
  mutations: {
    setDto (state, val) {
      state.dto = val
    },
    setPhones (state, val) {
      state.phones = val
    }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto */
    dto: new Dto(),
    phones: []
  })
}
