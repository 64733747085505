import { Dto as Customer } from '@/customers/viewModel'
import { Dto } from '@/shops/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

export const shops = {
  actions: {
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchCustomers (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const b = (await api.get(`/Shops/${id}/Customers`, tok)) ?? []
      const r = b.map((e) => Customer.fromApi(e))
      context.commit('setCustomers', r)
    },
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async fetchList (context, tok) {
      const b = (await api.get('/Shops', tok)) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setDtoList', r)
    },

    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const b = await api.get(`/Shops/${id}`, tok)
      const r = Dto.fromApi(b)
      context.commit('setDto', r)
    },

    /**
     * @param args {Object} - Vuex actions only support one parameter
     * @param args.model {Model} - Payload
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, args) {
      const m = valueFromKey('model', args)
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const d = await api.put(`/Shops/${id}`, m, tok)
      context.commit('setDto', d)
    }
  },
  getters: {
    customers (s) {
      return s.customers
    },
    dto (s) {
      return s.dto
    },
    dtoList (s) {
      return s.dtoList
    }
  },
  mutations: {
    setCustomers (state, val) {
      state.customers = val
    },
    setDtoList (state, val) {
      state.dtoList = val
    },
    setDto (state, val) {
      state.dto = val
    }
  },
  namespaced: true,
  state: () => ({
    /** @type Customer[] */
    customers: [],
    /** @type Dto */
    dto: new Dto(),
    /** @type dto[] */
    dtoList: []
  })
}
