import Vue from 'vue'
import VueRouter from 'vue-router'
import { authenticationGuard } from '@/auth/authenticationGuard'

Vue.use(VueRouter)

const routes = [
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './call-sessions/List.vue'),
    path: '/call-sessions',
    name: 'CallSessionList',
    children: [
      {
        component: () =>
          import(
            /* webpackChunkName: "ShopAdd" */ './call-sessions/ListMine.vue'
          ),
        path: 'mine',
        name: 'CallSessionListMine'
      },
      {
        component: () =>
          import(
            /* webpackChunkName: "ShopAdd" */ './call-sessions/ListInProgress.vue'
          ),
        path: 'in-progress',
        name: 'CallSessionListInProgress'
      },
      {
        component: () =>
          import(
            /* webpackChunkName: "ShopAdd" */ './call-sessions/ListAll.vue'
          ),
        path: 'all',
        name: 'CallSessionListAll'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './call-sessions/Add.vue'),
    path: '/call-sessions/add',
    name: 'CallSessionAdd'
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './call-sessions/Id.vue'),
    path: '/call-sessions/:id',
    name: 'CallSessionId',
    children: [
      {
        component: () =>
          import(
            /* webpackChunkName: "ShopAdd" */ './call-sessions/Details.vue'
          ),
        path: 'details',
        name: 'CallSessionDetails'
      },
      {
        component: () =>
          import(
            /* webpackChunkName: "ShopAdd" */ './call-sessions/FollowUps.vue'
          ),
        path: 'follow-ups',
        name: 'CallSessionFollowUps'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './customers/Id.vue'),
    path: '/customers/:id',
    name: 'CustomerId',
    children: [
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './customers/Details.vue'),
        path: 'details',
        name: 'CustomerDetails'
      },
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './customers/Phones.vue'),
        path: 'phone-numbers',
        name: 'PhoneNumbers'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './follow-ups/Id.vue'),
    path: '/followups/:id',
    name: 'FollowUpId',
    children: [
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './follow-ups/Details.vue'),
        path: 'details',
        name: 'FollowUpDetails'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './kpi-targets/List.vue'),
    path: '/kpi-targets',
    name: 'KPITargetList',
    children: []
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './kpi-targets/Id.vue'),
    path: '/kpi-targets/:id',
    name: 'KPITargetId',
    children: [
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './kpi-targets/Details.vue'),
        path: 'details',
        name: 'KPITargetDetails'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './shops/List.vue'),
    path: '/shops',
    name: 'ShopList'
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './shops/Import.vue'),
    path: '/shops/import',
    name: 'ShopImport'
  },
  {
    component: () => import(/* webpackChunkName: "ShopAdd" */ './shops/Id.vue'),
    path: '/shops/:id',
    name: 'ShopId',
    children: [
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './shops/Details.vue'),
        path: 'details',
        name: 'ShopDetails'
      },
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './shops/Customers.vue'),
        path: 'customers',
        name: 'ShopCustomerList'
      },
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './shops/FollowUps.vue'),
        path: 'followups',
        name: 'ShopFollowUpList'
      },
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './shops/RepairOrders'),
        path: 'repair-orders',
        name: 'RepairOrderList'
      },
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './shops/RepairOrderImport'),
        path: 'repair-order-import',
        name: 'RepairOrderImport'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './profiles/Id.vue'),
    path: '/profiles',
    name: 'ProfileId'
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './reports/List.vue'),
    path: '/reports',
    name: 'ReportList',
    children: [
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './reports/FollowUpTotals'),
        path: 'follow-up-totals',
        name: 'FollowUpTotals'
      }
    ]
  },
  {
    component: () =>
      import(/* webpackChunkName: "ShopAdd" */ './users/List.vue'),
    path: '/users',
    name: 'UserList'
  },
  {
    component: () => import(/* webpackChunkName: "ShopAdd" */ './users/Id.vue'),
    path: '/users/:id',
    name: 'UserId',
    children: [
      {
        component: () =>
          import(/* webpackChunkName: "ShopAdd" */ './users/Details.vue'),
        path: 'details',
        name: 'UserDetails'
      }
    ]
  },
  {
    path: '/'
  }
]

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
  authenticationGuard(to, from, next)
})

export default router
