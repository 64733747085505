import { Dto } from '@/users/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

const resource = 'Users'
export const users = {
  actions: {
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async fetchList (context, tok) {
      const b = await api.get('/Users', tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setList', r)
    },
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const b = await api.get(`/${resource}/${id}`, tok)
      const r = Dto.fromApi(b)
      context.commit('setDto', r)
    },
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async lookup (context, tok) {
      const b = await api.get('/Users/Lookup', tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setLookupList', r)
    },
    /**
     * @param args {Object} - Vuex actions only support one parameter
     * @param args.model {Model} - Payload
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, args) {
      const m = valueFromKey('model', args)
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const d = await api.put(`/${resource}/${id}`, m, tok)
      context.commit('setDto', d)
    }
  },
  getters: {
    dto (s) { return s.dto },
    lookupList (s) { return s.lookupList },
    list (s) { return s.list }
  },
  mutations: {
    setDto (s, v) { s.dto = v },
    setList (s, v) { s.list = v },
    setLookupList (s, v) { s.lookupList = v }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto */
    dto: new Dto(),
    /** @type dto[] */
    lookupList: [],
    /** @type dto[] */
    list: []
  })
}
