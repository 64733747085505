import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import { colors } from '@/colorScheme'

Vue.use(Vuetify)

export default new Vuetify({
  icons: { iconfont: 'fa' },
  theme: {
    options: { customProperties: true },
    themes: { light: colors }
  }
})
