import dayjs from 'dayjs'

import { FollowUpTotals as futDto } from '@/reports/viewModel'
import { api } from '@/api'

const resource = 'Reports'
export const reports = {
  actions: {
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async fetchFUTotals (context, tok) {
      const ed = dayjs(context.state.ed).format()
      const sd = dayjs(context.state.sd).format()
      const b = await api.get(`/${resource}/Follow-Up-Totals?start=${sd}&end=${ed}`, tok) ?? []
      const r = b.map((e) => futDto.fromApi(e))
      context.commit('setFUTotals', r)
    },
    setED (context, val) { context.commit('setED', val) },
    setSD (context, val) { context.commit('setSD', val) }
  },
  getters: {
    ed (s) { return s.ed },
    edFormat (s) { return dayjs(s.ed).format('YYYY-MM-DD') },
    fuTotals (s) { return s.fuTotals },
    sd (s) { return s.sd },
    sdFormat (s) { return dayjs(s.sd).format('YYYY-MM-DD') }
  },
  mutations: {
    setED (s, v) { s.ed = v },
    setFUTotals (s, v) { s.fuTotals = v },
    setSD (s, v) { s.sd = v }
  },
  namespaced: true,
  state: () => ({
    ed: dayjs(new Date()),
    fuTotals: [],
    sd: dayjs(new Date())
  })
}
