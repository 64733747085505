import { valueFromKey } from '@/utils'
import dayjs from 'dayjs'

/**
 * @description immutable structure returned by the api
 */
export class Dto {
  CustomerID;
  ExternalID;
  ID;
  IsPrimary;
  Number;
  Type;
  UpdatedAt;

  constructor () {
    this.CustomerID = null
    this.ExternalID = null
    this.ID = null
    this.IsPrimary = null
    this.Number = null
    this.Type = null
    this.UpdatedAt = null
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromJson (a) {
    const r = new Dto()
    r.CustomerID = valueFromKey('CustomerID', a)
    r.ExternalID = valueFromKey('ExternalID', a)
    r.ID = valueFromKey('ID', a)
    r.IsPrimary = valueFromKey('IsPrimary', a)
    r.Number = valueFromKey('Number', a)
    r.Type = valueFromKey('Type', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    return r
  }
}

export class Model {
  IsPrimary;
  Number;
  Type;

  constructor () {
    this.IsPrimary = null
    this.Number = null
    this.Type = null
  }

  get BirthdayFormat () {
    let r = null
    if (this.Birthday) r = dayjs(this.Birthday).format('YYYY-MM-DD')
    return r
  }

  static fromDto (a) {
    const r = new Model()
    r.IsPrimary = valueFromKey('IsPrimary', a)
    r.Number = valueFromKey('Number', a)
    r.Type = valueFromKey('Type', a)
    return r
  }
}

export const Rules = {
  Email: [
    (v) => !!v || 'E-mail is required',
    (v) => {
      return /.+@.+\..+/.test(v) || 'E-mail must be valid'
    }
  ],
  Name: [
    (v) => !!v || 'Name is required',
    (v) => {
      return (v && v.length >= 3) || 'Name must be at least 3 characters'
    }
  ],
  Phone: [(v) => !!v || 'Phone is required']
}
