import { Dto } from '@/call-sessions/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

const resource = 'Call-Sessions'

export const callSessions = {
  actions: {
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     */
    async fetchList (context, tok) {
      const b = await api.get(`/${resource}`, tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setList', r)
    },

    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     */
    async fetchInProgress (context, tok) {
      const b = await api.get(`/${resource}/In-Progress`, tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setInProgress', r)
    },

    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     */
    async fetchMine (context, tok) {
      const b = await api.get(`/${resource}/Mine`, tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setMine', r)
    },

    /**
     * @param a {Object} - Vuex actions can only support one parameter
     * @param a.id {string} - UUID
     * @param a.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchByShop (context, a) {
      const b = await api.get(`/Shops/${a.id}/${resource}`, a.tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setListByShop', r)
    },

    /**
     * @param a {Object} - Vuex actions can only support one parameter
     * @param a.id {string} - UUID
     * @param a.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, a) {
      const id = valueFromKey('id', a)
      const tok = valueFromKey('tok', a)
      const b = await api.get(`/${resource}/${id}`, tok)
      const r = Dto.fromApi(b)
      context.commit('setDto', r)
    },

    /**
     * @param a {Object} - Vuex actions only support one parameter
     * @param a.body {Model} - Payload
     * @param a.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async post (context, a) {
      const m = valueFromKey('model', a)
      const tok = valueFromKey('tok', a)
      const d = await api.post(`/${resource}`, m, tok)
      context.commit('setDto', d)
    },
    /**
     * @param a {Object} - Vuex actions only support one parameter
     * @param a.body {Model} - Payload
     * @param a.id {string} - UUID
     * @param a.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, a) {
      const m = valueFromKey('model', a)
      const tok = valueFromKey('tok', a)
      const d = await api.put(`/${resource}/${a.id}`, m, tok)
      context.commit('setDto', d)
    }
  },
  getters: {
    dto (s) { return s.dto },
    inProgress (s) { return s.inProgress },
    list (s) { return s.list },
    listByShop (s) { return s.listByShop },
    mine (s) { return s.mine }
  },
  mutations: {
    setDto (state, val) { state.dto = val },
    setInProgress (state, val) { state.inProgress = val },
    setList (state, val) { state.list = val },
    setListByShop (state, val) { state.listByShop = val },
    setMine (state, val) { state.mine = val }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto */
    dto: new Dto(),
    /** @type dto[] */
    inProgress: [],
    /** @type dto[] */
    list: [],
    /** @type dto[] */
    listByShop: [],
    /** @type dto[] */
    mine: []
  })
}
