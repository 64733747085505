// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig } from 'axios'
import { text } from '@/constants'
import { valueFromKey } from '@/utils'

const ax = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_API,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
})

export function parseAxiosError (e) {
  let result = text.errorGeneric
  if (process.env.NODE_ENV === 'development') {
    result = e.message
    // eslint-disable-next-line
    console.log(e.message)
    // Report additional info based on axios error
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      let data = { Messages: undefined }
      data = e.response?.data
      const messages = data?.Messages ?? []
      let msg = ''
      for (let i = 0; i < messages.length; i++) {
        const m = messages[i]
        msg = msg + `${m}\n`
      }
      result = `Status: ${e.response.status} | Error: ${msg} `
      // eslint-disable-next-line
      console.log(e.response)
    } else if (e.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // eslint-disable-next-line
      console.log(e.request)
    }
  }
  return result
}

export const api = {
  /**
   * @param res<string> resource string
   * @param tok<string> access token
   * @returns { Promise<any> }
   */
  async delete (res, tok) {
    const resp = await ax.delete(res, axConfig(tok))
    return valueFromKey('data', resp)
  },

  /**
   * @param res<string> resource string
   * @param tok<string> access token
   * @returns { Promise<any> }
   */
  async get (res, tok) {
    const resp = await ax.get(res, axConfig(tok))
    return valueFromKey('data', resp)
  },

  /**
   * @param res<string> resource string
   * @param dto<any> body payload
   * @param tok<string> access token
   * @returns { Promise<any> }
   */
  async post (res, dto, tok) {
    const resp = await ax.post(res, dto, axConfig(tok))
    return valueFromKey('data', resp)
  },

  /**
   * @param res<string> resource string
   * @param dto<any> body payload
   * @param tok<string> access token
   * @returns { Promise<any> }
   */
  async put (res, dto, tok) {
    const resp = await ax.put(res, dto, axConfig(tok))
    return valueFromKey('data', resp)
  }
}

/**
 * @param tok<string> Access Token
 * @returns {AxiosRequestConfig}
 */
function axConfig (tok) {
  return {
    headers: {
      Authorization: `Bearer ${tok}`
    }
  }
}
