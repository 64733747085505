import { Dto } from '@/kpi-targets/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

const resource = 'KPI-Target-Defaults'

export const kpiTargets = {
  actions: {
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     */
    async fetchList (context, tok) {
      const b = await api.get(`/${resource}`, tok) ?? []
      const r = b.map((e) => Dto.fromApi(e))
      context.commit('setList', r)
    },

    /**
     * @param a {Object} - Vuex actions can only support one parameter
     * @param a.id {string} - UUID
     * @param a.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, a) {
      const id = valueFromKey('id', a)
      const tok = valueFromKey('tok', a)
      const b = await api.get(`/${resource}/${id}`, tok)
      const d = Dto.fromApi(b)
      context.commit('setDto', d)
    },

    /**
     * @param a {Object} - Vuex actions only support one parameter
     * @param a.body {Model} - Payload
     * @param a.id {string} - UUID
     * @param a.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, a) {
      const m = valueFromKey('model', a)
      const tok = valueFromKey('tok', a)
      const d = await api.put(`/${resource}/${a.id}`, m, tok)
      context.commit('setDto', d)
    }
  },
  getters: {
    dto (s) { return s.dto },
    list (s) { return s.list }
  },
  mutations: {
    setDto (state, val) { state.dto = val },
    setList (state, val) { state.list = val }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto */
    dto: new Dto(),
    /** @type dto[] */
    list: []
  })
}
