
export const colors = {
  // background: '#eff1e3',
  // background: '#727981',
  onAccent: '#eee',
  // onBackground: '#1c2126',
  // onPrimary: '#fff',
  // onSecondary: '#fff',
  // onSurface: '#1c2126',
  // primary: '#4a56e2',
  // primaryDark: '#1c2126',
  // primaryDark: '#fff',
  // primaryLight: '#1c2126',
  secondary: '#424242',
  // secondaryDark: '#727981',
  // secondaryDark: '#f6f6f6',
  // secondaryLight: '#727981',
  accent: '#212121'
  // error: '#ff8576',
  // info: '#4a56e2',
  // success: '#24cca7',
  // warning: '#FFC107'
}
