import { valueFromKey } from '@/utils'

/**
 * @description immutable structure returned by the api
 */
export class FollowUpTotals {
  /** @type {String} - UUID */ ShopID
  /** @type {String} */ ShopName
  /** @type {Number} */ Billable
  /** @type {Number} */ Connection
  /** @type {Number} */ DoNotCall
  /** @type {Number} */ Duplicate
  /** @type {Number} */ Employee
  /** @type {Number} */ NoConnection
  /** @type {Number} */ NonBillable
  /** @type {Number} */ NoPhoneNumber
  /** @type {Number} */ NoResult
  /** @type {Number} */ Voicemail
  /** @type {Number} */ Total
  constructor () {
    this.ShopID = null
    this.ShopName = null
    this.Billable = null
    this.Connection = null
    this.DoNotCall = null
    this.Duplicate = null
    this.Employee = null
    this.NoConnection = null
    this.NonBillable = null
    this.NoPhoneNumber = null
    this.NoResult = null
    this.Voicemail = null
    this.Total = null
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromApi (a) {
    const d = new FollowUpTotals()
    d.ShopID = valueFromKey('ShopID', a)
    d.ShopName = valueFromKey('ShopName', a)
    d.Billable = valueFromKey('Billable', a)
    d.Connection = valueFromKey('Connection', a)
    d.DoNotCall = valueFromKey('DoNotCall', a)
    d.Duplicate = valueFromKey('Duplicate', a)
    d.Employee = valueFromKey('Employee', a)
    d.NoConnection = valueFromKey('NoConnection', a)
    d.NonBillable = valueFromKey('NonBillable', a)
    d.NoPhoneNumber = valueFromKey('NoPhoneNumber', a)
    d.NoResult = valueFromKey('NoResult', a)
    d.Voicemail = valueFromKey('Voicemail', a)
    d.Total = valueFromKey('Total', a)
    return d
  }
}
