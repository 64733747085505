import { valueFromKey } from '@/utils'

/**
 * @description immutable structure returned by the api
 */
export class Dto {
  Admin
  Email
  FirstName
  ID
  LastName
  PhoneNumber
  constructor () {
    this.Admin = false
    this.Email = null
    this.FirstName = null
    this.ID = null
    this.LastName = null
    this.PhoneNumber = null
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromApi (a) {
    const d = new Dto()
    d.Admin = valueFromKey('Admin', a)
    d.Email = valueFromKey('Email', a)
    d.FirstName = valueFromKey('FirstName', a)
    d.ID = valueFromKey('ID', a)
    d.LastName = valueFromKey('LastName', a)
    d.PhoneNumber = valueFromKey('PhoneNumber', a)
    return d
  }
}

export class Model {
  Admin
  FirstName
  LastName
  PhoneNumber
  constructor () {
    this.Admin = false
    this.FirstName = null
    this.LastName = null
    this.PhoneNumber = null
  }

  /**
   * @description load model from dto
   * @param a{Dto}
   */
  static fromDto (a) {
    const r = new Model()
    r.Admin = valueFromKey('Admin', a)
    r.FirstName = valueFromKey('FirstName', a)
    r.LastName = valueFromKey('LastName', a)
    r.PhoneNumber = valueFromKey('PhoneNumber', a)
    return r
  }
}
