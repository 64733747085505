import { valueFromKey } from '@/utils'

export const inCents = 'Currency'

/**
 * @description immutable structure returned by the api
 */
export class Dto {
  /** @type {String} */ ID
  /** @type {String} - ISO Date */ CreatedAt
  /** @type {String} - UUID */ CreatedBy
  /** @type {String} - ISO Date */ UpdatedAt
  /** @type {String} - UUID */ UpdatedBy
  /** @type {Number} */ High
  /** @type {Number} */ Low
  /** @type {Number} */ Medium
  /** @type {String} */ Name
  /** @type {'Currency' | 'Percentage'} */ Type

  get highDisplay () {
    let r = this.High
    if (this.Type === inCents) r = (this.High * 0.01).toFixed(2)
    return r
  }

  get lowDisplay () {
    let r = this.Low
    if (this.Type === inCents) r = (this.Low * 0.01).toFixed(2)
    return r
  }

  get mediumDisplay () {
    let r = this.Medium
    if (this.Type === inCents) r = (this.Medium * 0.01).toFixed(2)
    return r
  }

  constructor () {
    this.ID = null
    this.CreatedAt = null
    this.CreatedBy = null
    this.UpdatedAt = null
    this.UpdatedBy = null
    this.High = null
    this.Low = 0
    this.Medium = 0
    this.Name = 0
    this.Type = 'Currency'
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromApi (a) {
    const r = new Dto()
    r.ID = valueFromKey('ID', a)
    r.CreatedAt = valueFromKey('CreatedAt', a)
    r.CreatedBy = valueFromKey('CreatedBy', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    r.UpdatedBy = valueFromKey('UpdatedBy', a)
    r.High = valueFromKey('High', a)
    r.Low = valueFromKey('Low', a)
    r.Medium = valueFromKey('Medium', a)
    r.Name = valueFromKey('Name', a)
    r.Type = valueFromKey('Type', a)
    return r
  }
}

export class Model {
  High
  Low
  Medium

  constructor () {
    this.High = null
    this.Low = null
    this.Medium = null
  }

  /** @param a {Dto} */
  static fromDto (a) {
    const r = new Model()
    r.High = valueFromKey('High', a)
    r.Low = valueFromKey('Low', a)
    r.Medium = valueFromKey('Medium', a)
    return r
  }
}
