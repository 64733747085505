import { formatDate, valueFromKey } from '@/utils'
import { text } from '@/constants'

/**
 * @description immutable structure returned by the api
 */
export class Dto {
  AssignedTo
  AssignedToEmail
  ConnectionCount
  CreatedAt
  CreatedBy
  DeletedAt
  DeletedBy
  EndedOn
  FollowUpCount
  ID
  ShopID
  ShopName
  StartedOn
  Status
  UpdatedAt
  UpdatedBy

  get ConnectionRate () {
    if ((this.FollowUpCount ?? 0) === 0) return 0
    const r = 100 * (this.ConnectionCount / this.FollowUpCount)
    return `${r.toFixed(2)}%`
  }

  get Name () {
    return `${this.AssignedToEmail} | ${this.ShopName} | ${formatDate(this.StartedOn)} - ${formatDate(this.EndedOn)}`
  }

  constructor () {
    this.AssignedTo = null
    this.AssignedToEmail = null
    this.ConnectionCount = null
    this.CreatedAt = null
    this.CreatedBy = null
    this.DeletedAt = null
    this.DeletedBy = null
    this.EndedOn = null
    this.FollowUpCount = null
    this.ID = null
    this.ShopID = null
    this.ShopName = null
    this.StartedOn = null
    this.Status = null
    this.UpdatedAt = null
    this.UpdatedBy = null
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromApi (a) {
    const d = new Dto()
    d.AssignedTo = valueFromKey('AssignedTo', a)
    d.AssignedToEmail = valueFromKey('AssignedToEmail', a)
    d.ConnectionCount = valueFromKey('ConnectionCount', a)
    d.CreatedAt = valueFromKey('CreatedAt', a)
    d.CreatedBy = valueFromKey('CreatedBy', a)
    d.DeletedAt = valueFromKey('DeletedAt', a)
    d.DeletedBy = valueFromKey('DeletedBy', a)
    d.EndedOn = valueFromKey('EndedOn', a)
    d.FollowUpCount = valueFromKey('FollowUpCount', a)
    d.ID = valueFromKey('ID', a)
    d.ShopID = valueFromKey('ShopID', a)
    d.ShopName = valueFromKey('ShopName', a)
    d.Status = valueFromKey('Status', a)
    d.StartedOn = valueFromKey('StartedOn', a)
    d.UpdatedAt = valueFromKey('UpdatedAt', a)
    d.UpdatedBy = valueFromKey('UpdatedBy', a)
    return d
  }
}

export class Model {
  AssignedTo
  EndedOn
  ShopID
  StartedOn
  Status

  constructor () {
    this.AssignedTo = null
    this.EndedOn = null
    this.ShopID = null
    this.StartedOn = null
    this.Status = null
  }

  static fromDto (a) {
    const r = new Model()
    r.AssignedTo = valueFromKey('AssignedTo', a)
    r.EndedOn = valueFromKey('EndedOn', a)
    r.ShopID = valueFromKey('ShopID', a)
    r.Status = valueFromKey('Status', a)
    r.StartedOn = valueFromKey('StartedOn', a)
    return r
  }
}

export const Rules = {
  AssignedTo: [(v) => !!v || text.required],
  End: [(v) => !!v || text.required],
  Shop: [(v) => !!v || text.required],
  Start: [(v) => !!v || text.required],
  Status: [(v) => !!v || text.required]
}
