import { valueFromKey } from '@/utils'

export const externalSystems = ['Full Throttle', 'Tekmetric']

/**
 * @description immutable structure returned by the api
 */
export class Dto {
  Address;
  AmbassadorID;
  AmbassadorEmail;
  City;
  CreatedAt;
  CreatedBy;
  CrossStreets;
  Email;
  ExternalID;
  /** @type {'Full Throttle' | 'Tekmetric' | null } */
  ExternalSystem;
  ID;
  LogoURL;
  Name;
  Notes;
  Phone;
  PostalCode;
  Preferences;
  State;
  UpdatedAt;
  UpdatedBy;
  Warranty;
  WebURL;

  constructor () {
    this.Address = null
    this.AmbassadorID = null
    this.AmbassadorEmail = null
    this.City = null
    this.CreatedAt = null
    this.CreatedBy = null
    this.CrossStreets = null
    this.Email = null
    this.ExternalID = null
    this.ExternalSystem = null
    this.ID = null
    this.LogoURL = null
    this.Name = null
    this.Notes = null
    this.Phone = null
    this.PostalCode = null
    this.Preferences = null
    this.State = null
    this.UpdatedAt = null
    this.UpdatedBy = null
    this.Warranty = null
    this.WebURL = null
  }

  /**
   * @description load dto from resource endpoint response body
   * @param a{object} - object structure returned from resource endpoint
   */
  static fromApi (a) {
    const r = new Model()
    r.Address = valueFromKey('Address', a)
    r.AmbassadorID = valueFromKey('AmbassadorID', a)
    r.AmbassadorEmail = valueFromKey('AmbassadorEmail', a)
    r.City = valueFromKey('City', a)
    r.CreatedAt = valueFromKey('CreatedAt', a)
    r.CreatedBy = valueFromKey('CreatedBy', a)
    r.CrossStreets = valueFromKey('CrossStreets', a)
    r.Email = valueFromKey('Email', a)
    r.ExternalID = valueFromKey('ExternalID', a)
    r.ExternalSystem = valueFromKey('ExternalSystem', a)
    r.ID = valueFromKey('ID', a)
    r.LogoURL = valueFromKey('LogoURL', a)
    r.Name = valueFromKey('Name', a)
    r.Notes = valueFromKey('Notes', a)
    r.Phone = valueFromKey('Phone', a)
    r.PostalCode = valueFromKey('PostalCode', a)
    r.Preferences = valueFromKey('Preferences', a)
    r.State = valueFromKey('State', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    r.UpdatedBy = valueFromKey('UpdatedBy', a)
    r.Warranty = valueFromKey('Warranty', a)
    r.WebURL = valueFromKey('WebURL', a)
    return r
  }
}

export class Model {
  Address;
  AmbassadorID;
  City;
  CrossStreets;
  Email;
  ExternalID;
  /** @type {'Full Throttle' | 'Tekmetric' | null } */
  ExternalSystem;
  LogoURL;
  Name;
  Notes;
  Phone;
  PostalCode;
  Preferences;
  State;
  Warranty;
  WebURL;

  constructor () {
    this.Address = null
    this.AmbassadorID = null
    this.City = null
    this.CrossStreets = null
    this.Email = null
    this.ExternalID = null
    this.ExternalSystem = null
    this.LogoURL = null
    this.Name = null
    this.Notes = null
    this.Phone = null
    this.PostalCode = null
    this.Preferences = null
    this.State = null
    this.Warranty = null
    this.WebURL = null
  }

  static fromDto (a) {
    const r = new Model()
    r.Address = valueFromKey('Address', a)
    r.AmbassadorID = valueFromKey('AmbassadorID', a)
    r.City = valueFromKey('City', a)
    r.CrossStreets = valueFromKey('CrossStreets', a)
    r.Email = valueFromKey('Email', a)
    r.ExternalID = valueFromKey('ExternalID', a)
    r.ExternalSystem = valueFromKey('ExternalSystem', a)
    r.LogoURL = valueFromKey('LogoURL', a)
    r.Name = valueFromKey('Name', a)
    r.Notes = valueFromKey('Notes', a)
    r.Phone = valueFromKey('Phone', a)
    r.PostalCode = valueFromKey('PostalCode', a)
    r.Preferences = valueFromKey('Preferences', a)
    r.State = valueFromKey('State', a)
    r.Warranty = valueFromKey('Warranty', a)
    r.WebURL = valueFromKey('WebURL', a)
    return r
  }
}

export const Rules = {
  /*
  Email: [
    (v) => !!v || 'E-mail is required',
    (v) => { return /.+@.+\..+/.test(v) || 'E-mail must be valid' }
  ],
  */
  Name: [
    (v) => !!v || 'Name is required',
    (v) => {
      return (v && v.length >= 3) || 'Name must be at least 3 characters'
    }
  ],
  Phone: [(v) => !!v || 'Phone is required']
}
