<template>
  <div id="app">
    <v-app class="fill-height" dark>
      <v-overlay :value="loading"></v-overlay>
      <LeftNav />
      <v-main class="fill-height secondary lighten-5">
        <v-progress-linear
          :active="loading"
          color="accent"
          height="5"
          indeterminate
        />
        <v-alert
          v-model="alert.Active"
          :icon="alert.Icon"
          :type="alert.Type"
          class="ma-0"
          dismissible
        >
          {{ alert.Text }}
        </v-alert>
        <v-snackbar v-model="sb.Active" :color="sb.Color">
          <v-icon left>{{ sb.Icon }}</v-icon>
          {{ sb.Text }}
        </v-snackbar>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { icons, text } from '@/constants'
import LeftNav from '@/navigation/LeftNav.vue'

export default {
  name: 'App',
  components: { LeftNav },
  data: () => ({
    appName: text.appName,
    navDrawerShown: true
  }),
  methods: {
    logout () {
      this.$auth.logout({ returnTo: window.location.origin })
    }
  },
  computed: {
    ...mapGetters({
      alert: 'notifications/alertConfig',
      loading: 'notifications/loading',
      sb: 'notifications/snackbarConfig'
    }),
    authReady () {
      return !this.$auth.loading
    },
    i () {
      return icons
    },
    t () {
      return text
    }
  }
}
</script>
