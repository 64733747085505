import { api } from '@/api'
import { Dto as CustomerPhone } from '@/customer-phones/viewModel'
import { Dto } from '@/follow-ups/viewModel'
import { valueFromKey } from '@/utils'

export const followUps = {
  actions: {
    /**
     * @param args {Object} Vuex actions can only support one parameter
     * @param args.id {string} UUID
     * @param args.tok {string} Access Token
     * @param context {Object} Module instance injected by Vuex
     */
    async fetchByCallSession (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const apiList = await api.get(`/Call-Sessions/${id}/Follow-Ups`, tok)
      const result = apiList.map((e) => {
        return Dto.fromJson(e)
      })
      context.commit('setCallList', result)
    },
    async fetchPhoneNumbers (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const apiList = await api.get(`/Customers/${id}/Phone-Numbers`, tok)
      const result = apiList.map((e) => {
        return CustomerPhone.fromJson(e)
      })
      context.commit('setPhoneNumbers', result)
    },
    /**
     * @param args {Object} Vuex actions can only support one parameter
     * @param args.id {string} UUID
     * @param args.tok {string} Access Token
     * @param context {Object} Module instance injected by Vuex
     */
    async fetchByShop (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const apiList = await api.get(`/Shops/${id}/Follow-Ups`, tok)
      const result = apiList.map((e) => {
        return Dto.fromJson(e)
      })
      context.commit('setList', result)
    },

    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const r = await api.get(`/Follow-Ups/${id}`, tok)
      const d = Dto.fromJson(r)
      context.commit('setDto', d)
    },
    /**
     * @param args {Object} - Vuex actions only support one parameter
     * @param args.body {Model} - Payload
     * @param args.customerId {string}
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, args) {
      const body = valueFromKey('body', args)
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      if (body.DoNotCallInternal) {
        await api.put(
          `/Customers/${args.customerId}/DoNotCall`,
          { DoNotCallInternal: true },
          tok
        )
      }
      const r = await api.put(`/Follow-Ups/${id}`, body, tok)
      const d = Dto.fromJson(r)
      context.commit('setDto', d)
    },
    toggleDuplicates (context) {
      context.commit('setDuplicates', !context.state.duplicates)
    }
  },
  getters: {
    dto (s) {
      return s.dto
    },
    duplicates: (s) => s.duplicates,
    list (s) {
      let r = s.list.filter((e) => e.Result !== 'Duplicate')
      if (s.duplicates) r = s.list
      return r
    },
    listByCallSession (s) {
      let r = s.listByCallSession.filter((e) => e.Result !== 'Duplicate')
      if (s.duplicates) r = s.listByCallSession
      return r
    },
    phoneNumbers (s) {
      return s.phoneNumbers
    }
  },
  mutations: {
    setCallList (state, val) {
      state.listByCallSession = val
    },
    setDuplicates (state, val) {
      state.duplicates = val
    },
    setPhoneNumbers (state, val) {
      state.phoneNumbers = val
    },
    setDto (state, val) {
      state.dto = val
    },
    setList (state, val) {
      state.list = val
    }
  },
  namespaced: true,
  state: () => ({
    dto: new Dto(),
    duplicates: false,
    list: [],
    listByCallSession: [],
    phoneNumbers: []
  })
}
