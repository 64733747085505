import dayjs from 'dayjs'
import { valueFromKey } from '@/utils'

dayjs.extend(require('dayjs/plugin/utc'))

export class Dto {
  // customers
  Address1;
  Address2;
  City;
  DoNotCall;
  DoNotCallInternal;
  Email;
  FirstName;
  FullAddress;
  FullName;
  LastName;
  PostalCode;
  ShopID;
  State;
  // customer_phones
  PhoneNumber;
  PhoneType;
  // employees
  ServiceAdvisorFirst;
  ServiceAdvisorLast;
  // follow_ups
  ActionNeeded;
  Attempts;
  CalledAt;
  CallSessionID;
  Comments;
  CreatedAt;
  CreatedBy;
  Escalation;
  Highlight;
  ID;
  Opportunity;
  RepairOrderID;
  Result;
  ReviewCommitted;
  ScheduledAppointment;
  Sentiment;
  UpdatedAt;
  UpdatedBy;
  // repair_orders
  CompletedAt;
  CustomerID;
  Total;
  Type;
  VehicleID;
  // shops
  ShopName;
  ShopAddress;
  ShopCity;
  ShopCrossStreets;
  ShopNotes;
  ShopPostalCode;
  ShopPhone;
  ShopPreferences;
  ShopState;
  ShopURL;
  // vehicles
  Make;
  Model;
  Year;
  YearMakeModel;

  constructor () {
    // customers
    this.Address1 = null
    this.Address2 = null
    this.City = null
    this.DoNotCall = null
    this.DoNotCallInternal = null
    this.Email = null
    this.FirstName = null
    this.FullAddress = null
    this.FullName = null
    this.LastName = null
    this.PostalCode = null
    this.ShopID = null
    this.State = null

    // customer_phones
    this.PhoneNumber = null
    this.PhoneType = null

    // employees
    this.ServiceAdvisorFirst = null
    this.ServiceAdvisorLast = null

    // follow_ups
    this.ActionNeeded = null
    this.Attempts = null
    this.CalledAt = null
    this.CallSessionID = null
    this.Comments = null
    this.CreatedAt = null
    this.CreatedBy = null
    this.EmailReviewLinkToCustomer = null
    this.EmailShopImmediately = null
    this.HeadsUp = null
    this.Highlight = null
    this.ID = null
    this.NegativeFeedback = null
    this.Opportunity = null
    this.RepairOrderID = null
    this.Result = null
    this.ReviewCommitted = null
    this.ReviewRequested = null
    this.ScheduledAppointment = null
    this.Sentiment = null
    this.UpdatedAt = null
    this.UpdatedBy = null

    // repair_orders
    this.CompletedAt = null
    this.CustomerID = null
    this.Total = null
    this.Type = null
    this.VehicleID = null

    // shops
    this.ShopName = null
    this.ShopAddress = null
    this.ShopCity = null
    this.ShopCrossStreets = null
    this.ShopLogo = null
    this.ShopNotes = null
    this.ShopPhone = null
    this.ShopPostalCode = null
    this.ShopPreferences = null
    this.ShopState = null
    this.ShopURL = null

    // vehicles
    this.Make = null
    this.Model = null
    this.Year = null
    this.YearMakeModel = null
  }

  get ShopAvatarText () {
    return this.ShopName ? this.ShopName[0].toUpperCase() : ''
  }

  get DoNotCallDisplay () {
    return this.DoNotCall || this.DoNotCallInternal
  }

  static fromJson (a) {
    const r = new Dto()
    // customers
    r.Address1 = valueFromKey('Address1', a)
    r.Address2 = valueFromKey('Address2', a)
    r.City = valueFromKey('City', a)
    r.DoNotCall = valueFromKey('DoNotCall', a)
    r.DoNotCallInternal = valueFromKey('DoNotCallInternal', a)
    r.Email = valueFromKey('Email', a)
    r.FirstName = valueFromKey('FirstName', a)
    r.FullAddress = valueFromKey('FullAddress', a)
    r.FullName = valueFromKey('FullName', a)
    r.LastName = valueFromKey('LastName', a)
    r.PostalCode = valueFromKey('PostalCode', a)
    r.ShopID = valueFromKey('ShopID', a)
    r.State = valueFromKey('State', a)

    // customer_phones
    r.PhoneNumber = valueFromKey('PhoneNumber', a)
    r.PhoneType = valueFromKey('PhoneType', a)

    // employees
    r.ServiceAdvisorFirst = valueFromKey('ServiceAdvisorFirst', a)
    r.ServiceAdvisorLast = valueFromKey('ServiceAdvisorLast', a)

    // follow_ups
    r.ActionNeeded = valueFromKey('ActionNeeded', a)
    r.Attempts = valueFromKey('Attempts', a)
    r.CalledAt = valueFromKey('CalledAt', a)
    r.CallSessionID = valueFromKey('CallSessionID', a)
    r.Comments = valueFromKey('Comments', a)
    r.CreatedAt = valueFromKey('CreatedAt', a)
    r.CreatedBy = valueFromKey('CreatedBy', a)
    r.EmailReviewLinkToCustomer = valueFromKey('EmailReviewLinkToCustomer', a)
    r.EmailShopImmediately = valueFromKey('EmailShopImmediately', a)
    r.HeadsUp = valueFromKey('HeadsUp', a)
    r.Highlight = valueFromKey('Highlight', a)
    r.ID = valueFromKey('ID', a)
    r.NegativeFeedback = valueFromKey('NegativeFeedback', a)
    r.Opportunity = valueFromKey('Opportunity', a)
    r.RepairOrderID = valueFromKey('RepairOrderID', a)
    r.Result = valueFromKey('Result', a)
    r.ReviewCommitted = valueFromKey('ReviewCommitted', a)
    r.ReviewRequested = valueFromKey('ReviewRequested', a)
    r.ScheduledAppointment = valueFromKey('ScheduledAppointment', a)
    r.Sentiment = valueFromKey('Sentiment', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    r.UpdatedBy = valueFromKey('UpdatedBy', a)

    // repair_orders
    r.CompletedAt = valueFromKey('CompletedAt', a)
    r.CustomerID = valueFromKey('CustomerID', a)
    r.Total = valueFromKey('Total', a)
    r.Type = valueFromKey('Type', a)
    r.VehicleID = valueFromKey('VehicleID', a)

    // shop
    r.ShopName = valueFromKey('ShopName', a)
    r.ShopAddress = valueFromKey('ShopAddress', a)
    r.ShopCity = valueFromKey('ShopCity', a)
    r.ShopCrossStreets = valueFromKey('ShopCrossStreets', a)
    r.ShopLogo = valueFromKey('ShopLogo', a)
    r.ShopNotes = valueFromKey('ShopNotes', a)
    r.ShopPhone = valueFromKey('ShopPhone', a)
    r.ShopPostalCode = valueFromKey('ShopPostalCode', a)
    r.ShopPreferences = valueFromKey('ShopPreferences', a)
    r.ShopState = valueFromKey('ShopState', a)
    r.ShopURL = valueFromKey('ShopURL', a)

    // vehicles
    r.Make = valueFromKey('Make', a)
    r.Model = valueFromKey('Model', a)
    r.Year = valueFromKey('Year', a)
    r.YearMakeModel = valueFromKey('YearMakeModel', a)
    return r
  }
}

export class Model {
  ActionNeeded;
  Attempts;
  CalledAt;
  CallSessionID;
  Comments;
  CreatedAt;
  DoNotCallInternal;
  EmailReviewLinkToCustomer;
  EmailShopImmediately;
  HeadsUp;
  Highlight;
  NegativeFeedback;
  Opportunity;
  Result;
  ReviewCommitted;
  ReviewRequested;
  ScheduledAppointment;
  Sentiment;

  constructor () {
    this.ActionNeeded = null
    this.Attempts = null
    this.CalledAt = null
    this.CallSessionID = null
    this.Comments = null
    this.DoNotCallInternal = false
    this.EmailReviewLinkToCustomer = null
    this.EmailShopImmediately = null
    this.HeadsUp = null
    this.Highlight = null
    this.NegativeFeedback = null
    this.Opportunity = null
    this.Result = null
    this.ReviewCommitted = null
    this.ReviewRequested = null
    this.ScheduledAppointment = null
    this.Sentiment = null
  }

  get CalledAtFormat () {
    let r = null
    if (this.CalledAt) r = dayjs(this.CalledAt).format('YYYY-MM-DD')
    return r
  }

  get toApi () {
    let calledAt = null
    if (this.CalledAt) { calledAt = dayjs(dayjs(this.CalledAt).toLocaleString()).toISOString() }
    return {
      ActionNeeded: this.ActionNeeded,
      Attempts: this.Attempts,
      CalledAt: calledAt,
      CallSessionID: this.CallSessionID,
      Comments: this.Comments,
      EmailReviewLinkToCustomer: this.EmailReviewLinkToCustomer,
      EmailShopImmediately: this.EmailShopImmediately,
      HeadsUp: this.HeadsUp,
      Highlight: this.Highlight,
      NegativeFeedback: this.NegativeFeedback,
      Opportunity: this.Opportunity,
      Result: this.Result,
      ReviewCommitted: this.ReviewCommitted,
      ReviewRequested: this.ReviewRequested,
      ScheduledAppointment: this.ScheduledAppointment,
      Sentiment: this.Sentiment
    }
  }

  /**
   * @description hydrate dto props with response from API
   * @param d {Dto}
   * @return {Model}
   */
  static fromDto (d) {
    if (!d) throw Error('Invalid Argument: d: Dto')
    const r = new Model()
    r.ActionNeeded = valueFromKey('ActionNeeded', d)
    r.Attempts = valueFromKey('Attempts', d)
    r.CalledAt = valueFromKey('CalledAt', d)
    r.CallSessionID = valueFromKey('CallSessionID', d)
    r.Comments = valueFromKey('Comments', d)
    r.DoNotCallInternal = valueFromKey('DoNotCallInternal', d)
    r.EmailReviewLinkToCustomer = valueFromKey('EmailReviewLinkToCustomer', d)
    r.EmailShopImmediately = valueFromKey('EmailShopImmediately', d)
    r.HeadsUp = valueFromKey('HeadsUp', d)
    r.Highlight = valueFromKey('Highlight', d)
    r.NegativeFeedback = valueFromKey('NegativeFeedback', d)
    r.NegativeFeedback = valueFromKey('NegativeFeedback', d)
    r.Opportunity = valueFromKey('Opportunity', d)
    r.Result = valueFromKey('Result', d)
    r.ReviewCommitted = valueFromKey('ReviewCommitted', d)
    r.ReviewRequested = valueFromKey('ReviewRequested', d)
    r.ScheduledAppointment = valueFromKey('ScheduledAppointment', d)
    let s = valueFromKey('Sentiment', d) ?? ''
    if (s === '') s = 'Unknown'
    r.Sentiment = s
    return r
  }
}

export const Rules = {
  Comments: [(v) => !!v || 'Comments are required'],
  Sentiment: [
    (v) => !!v || 'Sentiment is required',
    (v) => v !== 'Unknown' || 'Sentiment is required'
  ]
}
