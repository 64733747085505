import Vue from 'vue'
import Vuex from 'vuex'
import { callSessions } from '@/call-sessions/module'
import { followUps } from '@/follow-ups/module'
import { kpiTargets } from '@/kpi-targets/module'
import { navigation } from '@/navigation/module'
import { notifications } from '@/notificatons/module'
import { reports } from '@/reports/module'
import { shops } from '@/shops/module'
import { users } from '@/users/module'
import { customers } from '@/customers/module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    callSessions,
    customers,
    followUps,
    kpiTargets,
    navigation,
    notifications,
    reports,
    shops,
    users
  }
})
